import { TIME_TYPES, SourceModelTypesEnum, DAY_TYPES, HOUR_TYPES } from 'Enums/SourceModelTypes';

const getTitleName = (selectedSummaryType, LANG, TEXT_TYPE) =>
  DAY_TYPES.includes(selectedSummaryType)
    ? 'Days'
    : HOUR_TYPES.includes(selectedSummaryType)
    ? 'Hours'
    : TIME_TYPES.includes(selectedSummaryType)
    ? 'Minutes'
    : SourceModelTypesEnum?.[selectedSummaryType]?.[LANG]?.[TEXT_TYPE] ?? '';

export default {
  getTitleName,
};
